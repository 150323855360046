<template>
  <div>
    <a-card
      :style="{ marginBottom: '1em' }"
      :header="$t('common.search')"
      :editable="false"
    >
      <template
        #header-buttons
      >
        <div class="is-pulled-right is-flex buttons">
          <b-button
            icon-left="file-excel-box"
            @click="() => $store.dispatch('ramsNodes/exportToCsv')"
          >
            {{ $t('common.navigation.export_to_csv') }}
          </b-button>
          <b-button
            icon-left="filter-remove"
            @click="() => $store.dispatch('ramsNodes/resetFilters')"
          >
            {{ $t('common.navigation.reset_filters') }}
          </b-button>
          <a-select
            :manual-doc="userDoc"
            field="routes.ramsNodes.orderBy"
            :label="$t('common.order')"
            :options="keys($store.getters['ramsNodes/orderOptions'])"
            :labels="$store.getters['ramsNodes/orderOptions']"
          />
        </div>
      </template>

      <filters />
      <a-input
        :style="{ marginTop: '1em' }"
        :manual-doc="userDoc"
        field="routes.ramsNodes.search"
        :label="$t('common.quick_search')"
      />
    </a-card>
    <a-table
      :columns="user.routes.ramsNodes.columns"
      :data="$store.getters['ramsNodes/displayedItems']"
      :router-link-fn="function(props) {
        return { name: 'failure', params: { id: props.row.failure.id }, query: { ramsNode: props.row.id }}
      }"
    />
    <more-docs-button
      module="ramsNodes"
    />
  </div>
</template>

<script>
import ATable from '@/components/ATable'
import MoreDocsButton from '@/components/MoreDocsButton'
import { mapGetters, mapActions } from 'vuex'
import { keys } from 'lodash'
import Filters from './RamsNodes/Filters'

export default {
  components: {
    Filters,
    MoreDocsButton
  },
  data() {
    return {
      userDoc: { module: 'user' }
    }
  },
  created () {
    this.$store.dispatch('ramsNodes/fetchDocs')
  },
  methods: {
    keys
  }
}
</script>
